<template>
  <div>
    <el-tabs v-model="state" @tab-click="getOrderPcFn" type="border-card">
      <el-tab-pane label="完成订单" name="1">
        <el-table :data="orders" style="width: 100%" size="small">
          <el-table-column
            :prop="key"
            :label="value"
            width="130"
            v-for="(value,key) in tablist2"
            :key="key"
          ></el-table-column>
          <el-table-column label="商品" align="center">
            <template slot-scope="scope">
              <div class="prodwrap">
                <div
                  v-for="(prod,index) in scope.row.products"
                  :key="index"
                >{{prod.prodName}} * {{prod.prodPrice}} * {{prod.quantity}}</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="paginationwrap-g">
          <el-pagination
            :page-size="count"
            :current-page="page"
            :page-count="9"
            layout="total, prev, pager, next"
            :total="total"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="预定订单" name="0">
        <el-table :data="orders" size="small">
          <el-table-column :prop="key" :label="value" v-for="(value,key) in tablist" :key="key"></el-table-column>
          <el-table-column label="商品" align="center">
            <template slot-scope="scope">
              <div class="prodwrap">
                <div
                  v-for="(prod,index) in scope.row.products"
                  :key="index"
                >{{prod.prodName}} * {{prod.prodPrice}} * {{prod.quantity}}</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="paginationwrap-g">
          <el-pagination
            :page-size="count"
            :current-page="page"
            :page-count="9"
            layout="total, prev, pager, next"
            :total="total"
            hide-on-single-page
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { getOrderPc } from "@/api/api";
export default {
  data() {
    return {
      orders: [],
      page: 1,
      count: 20,
      state: "1",
      total: 0,
      tablist: {
        genTime: "购买时间",
        amount: "金额",
        orderFrom: "订单来源",
        validDateShort: "有效日期"
      },
      tablist2: {
        orderSn: "订单号",
        amount: "金额",
        incomeStore: "收入",
        orderFrom: "订单来源",
        settleDate: "消费时间",
        casherId: "订单确认",
        settled: "资金"
      }
    };
  },
  mounted() {
    this.getOrderPcFn();
  },
  computed: {
    biz() {
      return this.$store.getters.getBiz;
    }
  },
  methods: {
    getOrderPcFn() {
      const that = this;
      this.orders = [];
      getOrderPc({
        count: that.count,
        page: that.page,
        state: that.state
      }).then(res => {
        if (res.result) {
          that.orders = res.data.orders;
          that.total = res.data.total;
        }
      });
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getOrderPcFn();
    }
  }
};
</script>

<style scoped>
.prodwrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.prodwrap div {
  margin: 0 10px;
}
.navbar {
  padding: 10px 5px;
  text-align: right;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}
.selected {
  padding: 10px 5px;
  text-align: right;
  background: #06375a;
  color: #fff;
  cursor: pointer;
}
.leftcell {
  display: table-cell;
  border-right: 1px solid #777;
  width: 8%;
}
.rightcell {
  padding: 10px 0 10px 30px;
  display: table-cell;
  box-sizing: border-box;
  background: #fff;
}
.ordernamewrap {
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 30px;
  font-size: 18px;
  border-bottom: 1px dashed #aaa;
}
</style>