<template>
  <div>
    <order />
  </div>
</template>

<script>
import order from "../components/Order.vue";
export default {
  components: {
    order
  }
};
</script>
